.header {
    display: flex;
    /* Use flexbox */
    justify-content: space-between;
    /* Distribute space between title and user profile */
    align-items: center;
    /* Vertically align items */
    background-color: #f0f0f0;
    /* Optional background color */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .header * {
    margin-left: 12px;
    margin-right: 12px;
  }
  
  .header .navbar {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  
  .header .navbar .navbarItem {
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .header .navbar .navbarItem:hover {
    background-color: #d3d3d3;
  }
  
  .header .title {
    font-size: 1.5rem;
    /* This will make the title larger than standard text */
    font-weight: bold;
    /* This will make the title bold */
    margin-top: 1rem;
    /* Adds some space above the title */
    margin-bottom: 1rem;
    /* Adds some space below the title */
    color: #333;
    /* This is a dark gray color, you can adjust it to your preference */
  }
  
  .header button {
    width: 6.5em;
    height: 2.5em;
  }
  
  
  /* Remove the hyperlink effect on page title */
  .header .navbar,a {
    color: inherit;
    text-decoration: none;
  }
  
  .header .navbar,a:hover {
    color: inherit;
    text-decoration: none;
    cursor: default;
  }
  
  .header .navbar,a:active {
    color: inherit;
    text-decoration: none;
  }
  
  .header .navbar,a:visited {
    color: inherit;
    text-decoration: none;
  }