.approval {
    display: flex;
    justify-content: center;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: #fff;
    background-color: #007BFF;
    margin: 12px
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .button:active {
    transform: scale(0.95);
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    transform: none;
  }