.notifyText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.container {
  margin: 12px;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
}