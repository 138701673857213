h3,
h4 {
    margin: 0;
}

pre {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    white-space: pre-wrap;
}

.card {
    background-color: white;
    border-radius: 12px;
    width: 80%;
}

.card>div:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.card h4 {
    font-size: 1.2em;
}

.cardElement {
    margin: 15px;
}

#cardStatus {
    background-color: rgb(240, 240, 255);
    padding: 15px;
}

#cardAuthor {
    display: flex;
    align-items: center;
    margin: 15px;
}

#cardCreatedByAvatar {
    margin-right: 10px;
    --size: 48px;
    width: var(--size);
    height: var(--size);
}

#cardCreatedByAvatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

#cardCreatedByName {
    margin-bottom: 5px;
}

#cardCreatedTime {
    font-size: 0.8em;
    color: #888;
}

#cardDetail {
    overflow-wrap: break-word;
}


#cardContentTable table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

#cardContentTable table tr:first-child td {
    border-top: 1px solid lightgrey;
}

#cardContentTable table tr td:first-child {
    font-weight: bold;
    width: auto;
}

#cardContentTable table tr td:last-child {
    width: 70%;
}

#cardContentTable table tr td {
    border-top: 1px solid lightgrey;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

#cardContentTable table tr td div {
    margin: 6px;
    padding-right: 12px;
}

#cardAttachment li {
    list-style-type: none;
}

#cardAttachment ul {
    padding-left: 20px;
}

#cardAttachment a {
    text-decoration: none;
    cursor: pointer;
}

#cardHistory table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    margin-bottom: 15px;
}

#cardHistory th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

#cardHistory th {
    background-color: #f2f2f2;
}

#cardHistory th:nth-child(3) {
    width: 50%;
    word-wrap: break-word;
}

#cardHistory tr:nth-child(even) {
    background-color: #f9f9f9;
}

#cardButton button:not(:first-child) {
    margin-left: 20px;
}

#cardOutcome {
    width: 60%;
    border-radius: 12px;
    border: 1px solid lightgray;
    margin: 15px;
}

#cardOutcome>div:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

#cardOutcome #cardOutcomeHeader {
    background-color: #ebf1f3;
    display: flex;
}

#cardOutcome #cardOutcomeText {
    display: inline-flex;
    align-items: center;
    flex: 1;
    padding-left: 20px;
}

#cardOutcome #cardCloseOutcomeButton {
    width: 30px;
    cursor: default;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 12px;
    color: #979797;
    font-size: 1.4em;
}

#cardOutcome #cardCloseOutcomeButton:hover {
    color: rgb(0, 0, 0);
}

#cardOutcome textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: none;
    border-right: none;
    font-size: 16px;
    resize: vertical;
    font-family: Arial, sans-serif;
    resize: none;
}

#cardOutcome textarea:focus {
    outline: none;
}

.submitButton{
    padding: 12px;
}