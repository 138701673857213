#ApprovalList {
  margin: 12px;
}

#ApprovalList table {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, sans-serif;
  margin-bottom: 10px;
}

#ApprovalList thead {
  background-color: #575757;
  color: #fff;
}

#ApprovalList th, #ApprovalList td {
  border: 1px solid #ddd;
  padding-left: 12px;
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
}

#ApprovalList th {
  font-weight: bold;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
}

#ApprovalList td {
  vertical-align: middle;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

#ApprovalList th:last-child,
#ApprovalList td:last-child {
  text-align: center;
}

#ApprovalList tbody tr:nth-child(even) {
  background-color: #e1e1e1;
}

#ApprovalList tbody tr:hover {
  background-color: #d0d0d0;
}

